import React from 'react';
import { Image } from '@chakra-ui/core';
import Marquee from 'react-fast-marquee';

import { colors } from '@theme';
import { cloudfrontBaseUrl } from '../../lib/consts';

const AsSeenIn = () => {
  const BusinessInsider = `${cloudfrontBaseUrl}/homepage/businessinsider.png`;
  const Bloomberg = `${cloudfrontBaseUrl}/homepage/bloomberg.png`;
  const Buzzfeed = `${cloudfrontBaseUrl}/homepage/buzzfeed.png`;
  const Investopedia = `${cloudfrontBaseUrl}/homepage/investopedia2.png`;
  const Refinery = `${cloudfrontBaseUrl}/homepage/refinery29.2.png`;
  const Techcrunch = `${cloudfrontBaseUrl}/homepage/techcrunch.png`;
  const UsaToday = `${cloudfrontBaseUrl}/homepage/usatoday.png`;

  return (
    <Marquee
      gradient={false}
      speed={75}
      style={{
        width: '100%',
        height: '100px',
        backgroundColor: colors.black,
      }}
    >
      <Image {...styles.img} src={Investopedia} />
      <Image {...styles.img} src={BusinessInsider} />
      <Image {...styles.img} src={Bloomberg} />
      <Image {...styles.img} src={Buzzfeed} />
      <Image {...styles.img} src={Refinery} />
      <Image {...styles.img} src={Techcrunch} />
      <Image {...styles.img} src={UsaToday} />
    </Marquee>
  );
};

const styles = {
  img: {
    mx: '74px',
    height: '24px',
  },
};

export default AsSeenIn;
